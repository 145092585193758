import React from "react";
import RequestPassword from "./actions/request-password";
import ChangePassword from "./actions/change-password";
import CancelSuscription from "./actions/cancel-suscription";
import InactiveUser from "./actions/inactive-user";
import ActiveSinpe from "./actions/sinpe/sinpe-anual";
import ActiveSinpeMensual from "./actions/sinpe/sinpe-mensual";
import UpdateSinpeMensual from "./actions/sinpe/update-sinpe";
import ActiveTeamDelfino from "./actions/active-team-delfino";
import { Container, Title } from "../../components/styles/";

function Actions({ status, subscriptionToken = false, email }) {
	let isUserSinpeActive = status === 1 && subscriptionToken === "SINPE";

	return (
		<Container className="mb">
			<Title>Acciones - Usuario suscripcion tipo SINPE</Title>

			{isUserSinpeActive ? (
				<UpdateSinpeMensual />
			) : (
				<>
					<ActiveSinpe />
					<ActiveSinpeMensual />
				</>
			)}

			<Title>Acciones - Inabilitar / Cancelar</Title>
			{status > 0 && status < 4 && <CancelSuscription />}
			{status !== 0 && <InactiveUser />}

			<Title>Acciones - Contraseña</Title>
			<RequestPassword email={email} />
			<ChangePassword />

			<Title>Acciones - Activar cuenta delfino</Title>
			<ActiveTeamDelfino />
		</Container>
	);
}

export default Actions;
