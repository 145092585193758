import React from "react";
import gql from "graphql-tag";
import moment from "moment";
import styled from "styled-components";
import { useMutation } from "@apollo/react-hooks";
import { useParams } from "react-router-dom";
import { Form, Button } from "../../../../components/styles";
import { Modal, Error } from "../../../../components";
import useVisible from "../../../../hooks/useModal";

const P = styled.p`
	color: #565a6e;
	font-size: 14px;
`;

const formatDateUnix = (date) => {
	return moment.utc(date).unix();
};

const ACTIVE_ACCOUNT_SINPE = gql`
	mutation UPDATE_ACCOUNT_SINPE($ID: Int!, $date: String!) {
		updateAccountSINPE(ID: $ID, date: $date) {
			message
		}
	}
`;

function UPDATESINPE() {
	const { id } = useParams();
	const [visible, toggle] = useVisible(true);
	const [updateDate, setUpdateDate] = React.useState();
	const [requestActiveAccountSINPE, { loading, error, data }] =
		useMutation(ACTIVE_ACCOUNT_SINPE);

	const formHandler = (e) => {
		e.preventDefault();

		if (!updateDate) {
			window.confirm("Seleccionar fecha");
		} else if (window.confirm("Confirmar actualizar cuenta SINPE")) {
			requestActiveAccountSINPE({
				variables: {
					ID: parseInt(id, 10),
					date: updateDate + " 18:00:00",
				},
			});
		}
	};
	if (error) {
		console.log(error);
	}

	return (
		<>
			<Form style={{ marginTop: "2rem" }} onSubmit={formHandler}>
				{error && <Error error={error} />}
				<fieldset disabled={loading} aria-busy={loading}>
					<label htmlFor="password">Actualizar cuenta sinpe</label>
					<P>
						<input
							type="date"
							id="dateEnd"
							name="dateEnd"
							required
							onChange={({ target }) => setUpdateDate(target.value)}
						/>
					</P>
					<Button type="submit" backgroundColor="green">
						Actualizar
					</Button>
				</fieldset>
			</Form>
			<Modal visible={visible && data} hide={toggle}>
				{data && <p>{data.updateAccountSINPE.message}</p>}
			</Modal>
		</>
	);
}

export default UPDATESINPE;
